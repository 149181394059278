import {Button, Grid} from "@mui/material";
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'
import React, {useEffect, useState} from "react";

import styles from './Signature.module.css'
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface Props {
    onChange: (signature: string) => void;
    initialSignature: string;
}

export default function Signature({onChange, initialSignature}: Props) {

    const [initialSignatureState, setInitialSignature] = useState<string>(initialSignature)

    let signature: ReactSignatureCanvas | null = null;

    useEffect(() => {
        setInitialSignature(initialSignature)
        signature?.fromDataURL(initialSignature)
    }, [initialSignature])

    const onDrawEnd = (e: any) => {
        // Get base64 data from canvas
        onChange(e.srcElement.toDataURL().toString())
    }

    const clearSignature = () => {
        // Clear signature canvas and reset state
        signature?.clear();
        onChange('')
    }

    return <Grid item xs={12}>
        <h3 style={{marginTop: 0}}>Handtekening</h3>
        <div style={{width: '100%', position: 'relative'}}>
            <SignatureCanvas ref={(ref) => signature = ref} canvasProps={{className: styles.signatureCanvas}}
                             onEnd={onDrawEnd}/>
        <Button style={{position: 'absolute', bottom: '24px', right: 0, width: '30px', height: '30px'}} onClick={clearSignature}><RestartAltIcon sx={{ width: '30px', height: '30px'}}/></Button>
        </div>
    </Grid>

}