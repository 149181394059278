import PageLayout from "../../components/MIT/PageLayout";
import {Card, CardActionArea, Fab, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";
import EditDayEntryModal from "@/features/hours/EditDayEntryModal";
import AddDayEntryModal from "@/features/hours/AddDayEntryModal";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";

interface DayEntry {
    uuid: string,
    date: string,
    start_time: string,
    end_time: string,
    break_minutes: string,
    entry_type: string
}

const ENTRY_TYPES = {
    WORK: 'Werkdag',
    HOLIDAY: 'Vakantie',
    SICK: 'Ziek',
    SCHOOLING: 'Scholing',
    BANK_HOLIDAY: 'Feestdag',
    OTHER: 'Overig',
};

const fetchEntryType = (entryType: string) => {
    // Check if entryType is a valid key in ENTRY_TYPES
    if (entryType in ENTRY_TYPES) {
        // @ts-ignore
        return ENTRY_TYPES[entryType];
    }
};


export default function Hours() {

    const [hourEntries, setHourEntries] = useState<DayEntry[]>([])
    const [selectedHoursEntry, setSelectedHoursEntry] = useState<DayEntry | null>(null)
    const [addOpen, setAddOpen] = useState(false)

    useEffect(() => {
        fetchDayEntries()
    }, [])

    const fetchDayEntries = () => {
        axiosInstance.get('/day-entries/get_for_employee/?this_month=true').then(response => {
            setHourEntries(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const mappedHourEntries = hourEntries.map((entry, index) => {
        const date = moment(entry.date).format('DD-MM-YYYY')
        const dateIsToday = date === moment().format('DD-MM-YYYY')

        return <Card key={index} sx={{margin: '8px 16px', background: dateIsToday ? '#dfffc9' : '#fff'}}>
            <CardActionArea sx={{padding: '16px', fontSize: '1.1rem'}} onClick={() => setSelectedHoursEntry(entry)}>
                <Grid container>
                    <Grid item xs={3} sx={{textAlign: 'center'}}>{moment(entry.date).format('DD-MM-YYYY')}</Grid>
                    <Grid item xs={2}
                          sx={{textAlign: 'center'}}>{entry.start_time ? moment(entry.start_time, "HH:mm:ss.SSSSSS").format('HH:mm') : ''}</Grid>
                    <Grid item xs={2}
                          sx={{textAlign: 'center'}}>{entry.end_time ? moment(entry.end_time, "HH:mm:ss.SSSSSS").format('HH:mm') : ''}</Grid>
                    <Grid item xs={2} sx={{textAlign: 'center'}}>{entry.break_minutes}</Grid>
                    <Grid item xs={3} sx={{textAlign: 'center'}}>{fetchEntryType(entry.entry_type)}</Grid>
                </Grid>
            </CardActionArea>
        </Card>
    })

    const clearSelectedHoursEntry = (refresh: boolean) => {
        setSelectedHoursEntry(null)
        if (refresh) {
            fetchDayEntries();
        }
    }

      const addDayEntrySuccessCallback = (refresh: boolean) => {
        setAddOpen(false)
        if (refresh) {
            fetchDayEntries();
        }
    }

    return <PageLayout title={'Uren | klik om te bewerken'} backButton={true}>
        <div style={{
            width: '100%',
            height: '100%',
        }}>
            <EditDayEntryModal editDayEntry={selectedHoursEntry} editSuccessCallback={clearSelectedHoursEntry}/>
            <AddDayEntryModal open={addOpen} addSuccessCallback={addDayEntrySuccessCallback}/>
            <Grid container sx={{padding: '8px'}}>
                <Grid item xs={3} sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>Dag</Grid>
                <Grid item xs={2} sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>Starttijd</Grid>
                <Grid item xs={2} sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>Eindtijd</Grid>
                <Grid item xs={2} sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>Pauze</Grid>
                <Grid item xs={3} sx={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>Soort dag</Grid>
            </Grid>

            {mappedHourEntries}
            <Fab sx={{position: 'fixed', bottom: '32px', right: '32px'}} color="primary"
                 aria-label="Uren klokken" onClick={() => setAddOpen(true)}>
                <AlarmAddIcon/>
            </Fab>
        </div>
    </PageLayout>
}