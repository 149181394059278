import {Card, CardActionArea,} from '@mui/material';

interface ClickableListItemProps {
    children: React.ReactNode;
    onClick: () => void;
}

export default function ClickableListItem({children, onClick}: ClickableListItemProps) {
    return <Card sx={{border: '1px solid #cdcdcd', boxShadow: 'none', borderRadius: '0px !important'}}>
        <CardActionArea sx={{padding: '16px'}} onClick={onClick}>
            {children}
        </CardActionArea>
    </Card>
}