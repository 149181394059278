import React, {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import moment from "moment";
import {useForm} from "react-hook-form";
import {DateField, InputNumberField, StaticSelectField} from "@meierij-it/react-components-v2";
import axiosInstance from "@/features/auth/authService";
import {MobileDatePicker, MobileTimePicker} from "@mui/x-date-pickers";

interface DayEntry {
    uuid: string,
    date: string,
    start_time: string,
    end_time: string,
    break_minutes: string,
    entry_type: string
}

interface Props {
    open: boolean;
    addSuccessCallback: (refresh: boolean) => void;
}

const ENTRY_TYPES = {
    WORK: 'Werkdag',
    HOLIDAY: 'Vakantie',
    SICK: 'Ziek',
    SCHOOLING: 'Scholing',
    BANK_HOLIDAY: 'Feestdag',
    OTHER: 'Overig',
};

export default function AddDayEntryModal({open, addSuccessCallback}: Props) {

    const [startTime, setStartTime] = React.useState<string>(moment().format('HH:mm'))
    const [endTime, setEndTime] = React.useState<string | null>(null)
    const [date, setDate] = React.useState<string>(moment().format('DD-MM-YYYY'))

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: {isDirty},
        reset
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            break_minutes: 60,
            entry_type: {label: ENTRY_TYPES.WORK, value: 'WORK'}
        }
    })

    const saveDayEntry = (data: any) => {
        const postData = {
            ...data,
            date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            start_time: `${startTime}:00`,
            end_time: endTime ? `${endTime}:00` : null,
            entry_type: data.entry_type.value,
            break_minutes: data.break_minutes ? parseInt(data.break_minutes) : 0
        }

        axiosInstance.post(`day-entries/create_entry/`, postData).then(response => {
            addSuccessCallback(true)
        }).catch(error => console.log(error))

    }

    return <Dialog open={open} onClose={addSuccessCallback} maxWidth="sm" fullWidth>

        <form onSubmit={handleSubmit(saveDayEntry)}>
            <DialogTitle>Klokregel toevoegen</DialogTitle>
            <DialogContent>
                <Grid item sx={{padding: '16px'}}>
                    <MobileDatePicker sx={{width: '100%'}}
                                      value={moment(date, 'DD-MM-YYYY')}
                                      onChange={(newValue) => {
                                          if (!newValue) return;
                                          setDate(newValue.format("DD-MM-YYYY"))
                                      }} />
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                      label={'Starttijd (uren:minuten)'}
                                      onChange={(newValue) => {
                                          if (!newValue) return;
                                          setStartTime(newValue.format('HH:mm'))
                                      }}
                    />
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                      label={'Eindtijd (uren:minuten)'}
                                      onChange={(newValue) => {
                                          setEndTime(newValue ? newValue.format('HH:mm') : null)
                                      }}
                    />
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <InputNumberField name={'break_minutes'} control={control} label={'Pauze minuten'}
                                      type={'number'}/>
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <StaticSelectField
                        {...register("entry_type")}
                        isClearable
                        label={'Type'}
                        control={control}
                        name="entry_type"
                        isRequired={true}
                        options={[
                            {label: 'Werkdag', value: 'WORK'},
                            {label: 'Vakantie', value: 'HOLIDAY'},
                            {label: 'Ziek', value: 'SICK'},
                            {label: 'Scholing', value: 'SCHOOLING'},
                            {label: 'Feestdag', value: 'BANK_HOLIDAY'},
                            {label: 'Overig', value: 'OTHER'}
                        ]}
                    />

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={() => addSuccessCallback(false)}>Annuleren</Button>
                <Button color={"error"} disabled={!isDirty && (!startTime)} type={'submit'}>Opslaan</Button>
            </DialogActions>
        </form>
    </Dialog>
}