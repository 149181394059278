import {Grid} from "@mui/material";
import {CheckboxField} from "@meierij-it/react-components-v2";

interface Props {
    control: any;
}


export default function ExecutedTasks({control}: Props) {

    return <Grid item xs={12} style={{zIndex: 1}}>
        <h3 style={{verticalAlign: 'middle !important'}}>Uitgevoerde werkzaamheden</h3>
        <Grid container xs={12} style={{padding: '16px', zIndex: 1}}>
            <Grid item xs={6}>
                <CheckboxField name={'add_water'} control={control} label={'Water toevoegen'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'liquid_fertilization'} control={control} label={'Vloeibare bemesting'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'remove_yellow_leaves'} control={control} label={'Verwijderen van geel blad'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'remove_leaves_and_spray'} control={control}
                               label={'Bladeren afnemen en/of sproeien'}/>

            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'pruning_correction_growth'} control={control}
                               label={'Snoeien ter correctie van groei'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'deal_with_diseases'} control={control} label={'Behandelen ziekte of plaag'}/>

            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'remove_impurities'} control={control}
                               label={'Verwijderen van verontreiniging'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'clean_planters'} control={control} label={'Afnemen van de plantenbak'}/>

            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'fill_hydro_granules'} control={control} label={'Bijvullen van hydrokorrels'}/>

            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'check_water_meters'} control={control}
                               label={'Controle watermeters / zwenkwielen'}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField name={'bi_annual_fertilization'} control={control} label={'Halfjaarse bemesting'}/>
            </Grid>

        </Grid>

    </Grid>
}