import { API_URL } from '@/Constants';
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {create} from "zustand";

let refreshingFunc: Promise<[string, string]> | undefined;

interface AuthState {
  isLoggedIn: boolean
  user: any
  isAuthLoading: boolean
  setIsLoggedIn: (isLoggedIn: boolean) => void
  setUser: (user: any) => void
  setIsAuthLoading: (isAuthLoading: boolean) => void
}

export const useAuthStore = create<AuthState>()((set) => ({
  isLoggedIn: false,
  user: null,
  isAuthLoading: true,
  setIsLoggedIn: (isLoggedIn: boolean) => set({isLoggedIn: isLoggedIn}),
  setUser: (user: any) => set({user: user}),
  setIsAuthLoading: (isAuthLoading: boolean) => set({isAuthLoading: isAuthLoading}),
}))

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem("van_der_vleuten_pwa_token");

    if (!token) {
      // Redirect to the login page if no token is available
      window.location.href = `${window.location.origin}/login?source=interceptor`;
      return Promise.reject("No token available");
    }

    // Attach the token to the request headers
    config.headers = {
      'Authorization': `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => res,
  async (error: AxiosError) => {
    const originalConfig = error.config as AxiosRequestConfig;
    const token = localStorage.getItem("van_der_vleuten_pwa_token");

    // Check for an unauthorized error and token existence in local storage
    if (!token || !isUnauthorizedError(error)) {
      return Promise.reject(error);
    }

    try {
      // The key approach employed here is utilizing a global function pointer, `refreshingFunc`.
      // This design ensures that multiple expired requests share the same function, avoiding redundant token renewal requests.
      if (!refreshingFunc) {
        refreshingFunc = renewToken();
      }

      const [newToken, newRefreshToken] = await refreshingFunc;

      // Update local storage with the new token
      localStorage.setItem("van_der_vleuten_pwa_token", newToken);
      // localStorage.setItem("refreshToken", newRefreshToken);

      // Update the request header with the new token
      originalConfig.headers!.Authorization = `Bearer ${newToken}`;

      // Retry the original request
      try {
        return await axios.request(originalConfig);
      } catch (innerError) {
        if (axios.isAxiosError(innerError)) {
          // If the original request failed with 401 again, it means the server returned an invalid token for the refresh request
          if (isUnauthorizedError(innerError)) {
            throw innerError;
          }
        }
      }
    } catch (err) {
      // Clear the token from local storage and redirect to the login page on error
      localStorage.removeItem("van_der_vleuten_pwa_token");
      // localStorage.removeItem("refreshToken");
      window.location.href = `${window.location.origin}/login?source=interceptor`;
    } finally {
      // Reset refreshingFunc after completion
      refreshingFunc = undefined;
    }
  }
);

function isUnauthorizedError(error: AxiosError) {
  const { response } = error;

  if (response && response.status !== undefined) {
    // Check if the error response is unauthorized (status code 401)
    return response.status === 401;
  }

  // Log a message if the response is undefined, or the status is not available
  console.log('Response is undefined, or the status is not available.');
}

export async function authenticate(email: string, password: string): Promise<[string, string]> {
  const loginPayload = {
    email: email,
    password: password
  };

  // Make a POST request to authenticate and get tokens
  const response = await axios.post(`${API_URL}/api/auth/jwt/token/`, loginPayload, {
    withCredentials: true
  });

  const token = response.data.access;
  // const refreshToken = response.data.refreshToken;
  const refreshToken = '';

  useAuthStore.setState({
    isAuthLoading: false,
    isLoggedIn: true
  })

  return [token, refreshToken];
}

export async function renewToken(): Promise<[string, string]> {
  // const refreshToken = localStorage.getItem("refreshToken");

  // if (!refreshToken)
  //   throw new Error('Refresh token does not exist');

  // const refreshPayload = {
  //   refreshToken: refreshToken
  // };

  const refreshPayload = {}

  // Make a POST request to refresh the token
  const response = await axios.post(`${API_URL}/api/auth/jwt/token/refresh/`, refreshPayload, {
    withCredentials: true
  });
  const token = response.data.access;

  // const newRefreshToken = response.data.refreshToken;
  const newRefreshToken = '';

  useAuthStore.setState({
    isAuthLoading: false,
    isLoggedIn: true
  })

  return [token, newRefreshToken];
}

export default axiosInstance;