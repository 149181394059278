import {Navigate, useLocation} from 'react-router-dom';
import { useAuthStore } from '@/features/auth/authService';

export default function Page() {
    const location = useLocation();

    const { isLoggedIn, isAuthLoading } = useAuthStore((state) => state)

    if (!isAuthLoading && !isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login?source=landing" state={{ from: location.pathname }} />    
    }

    return <Navigate to="/workorders" state={{ from: location.pathname }} />
}