

interface Props{
    children: React.ReactNode
}

export default function BottomActionBar({children}: Props){
    return <div style={{
        width: '100vw',
        height: '64px',
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#4AA560',
    }}>
        {children}
    </div>
}