import React, {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import moment from "moment";
import {useForm} from "react-hook-form";
import {InputField, InputNumberField, StaticSelectField} from "@meierij-it/react-components-v2";
import axiosInstance from "@/features/auth/authService";
import {MobileDatePicker, MobileTimePicker} from "@mui/x-date-pickers";

interface DayEntry {
    uuid: string,
    date: string,
    start_time: string,
    end_time: string,
    break_minutes: string,
    entry_type: string
}

interface Props {
    editDayEntry?: DayEntry | null;
    editSuccessCallback: (refresh: boolean) => void;
}

const ENTRY_TYPES = {
    WORK: 'Werkdag',
    HOLIDAY: 'Vakantie',
    SICK: 'Ziek',
    SCHOOLING: 'Scholing',
    BANK_HOLIDAY: 'Feestdag',
    OTHER: 'Overig',
};

const fetchEntryType = (entryType: string) => {
    // Check if entryType is a valid key in ENTRY_TYPES
    if (entryType in ENTRY_TYPES) {
        // @ts-ignore
        return {label: ENTRY_TYPES[entryType], value: entryType};
    }
};

export default function EditDayEntryModal({editDayEntry, editSuccessCallback}: Props) {

    const [startTime, setStartTime] = React.useState<string>(moment().format('HH:mm'))
    const [endTime, setEndTime] = React.useState<string | null>(null)

    const {
        control,
        register,
        handleSubmit,
        formState: {isDirty},
        reset
    } = useForm({
        shouldUnregister: true
    })

    useEffect(() => {
        if (editDayEntry) {
            reset({
                date: editDayEntry.date,
                break_minutes: editDayEntry.break_minutes,
                entry_type: fetchEntryType(editDayEntry.entry_type)
            })
            setStartTime(moment(editDayEntry.start_time, "HH:mm:ss.SSSSSS").format('HH:mm'))
            setEndTime(editDayEntry.end_time ? moment(editDayEntry.end_time, "HH:mm:ss.SSSSSS").format('HH:mm') : null)
        }
    }, [editDayEntry])

    const saveDayEntry = (data: any) => {
        const postData = {
            ...data,
            start_time: `${startTime}:00`,
            end_time: endTime ? `${endTime}:00` : null,
            entry_type: data.entry_type.value,
            break_minutes: data.break_minutes ? parseInt(data.break_minutes) : 0
        }

        axiosInstance.post(`day-entries/${editDayEntry?.uuid}/update_entry/`, postData).then(response => {
            editSuccessCallback(true)
        }).catch(error => console.log(error))

    }

    return <Dialog open={!!editDayEntry} onClose={editSuccessCallback} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(saveDayEntry)}>
            <DialogTitle>Klokregel wijzigen - {moment(editDayEntry?.date).format("DD-MM-YYYY")}</DialogTitle>
            <DialogContent>
                <Grid item sx={{padding: '16px'}}>
                    <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                      label={'Starttijd (uren:minuten)'}
                                      value={moment(startTime, 'HH:mm')}
                                      onChange={(newValue) => {
                                          if (!newValue) return;
                                          setStartTime(newValue.format('HH:mm'))
                                      }}
                    />
                </Grid>
                <Grid item sx={{padding: '16px'}}>

                    <MobileTimePicker sx={{width: '100%'}} format={"HH:mm"} ampm={false}
                                      label={'Eindtijd (uren:minuten)'}
                                      value={endTime ? moment(endTime, 'HH:mm') : null}
                                      onChange={(newValue) => {
                                          setEndTime(newValue ? newValue.format('HH:mm') : null)
                                      }}
                    />
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <InputNumberField name={'break_minutes'} control={control} label={'Pauze minuten'} type={'number'}/>
                </Grid>
                <Grid item sx={{padding: '16px'}}>
                    <StaticSelectField
                        {...register("entry_type")}
                        isClearable
                        label={'Type'}
                        control={control}
                        name="entry_type"
                        isRequired={true}
                        options={[
                            {label: 'Werkdag', value: 'WORK'},
                            {label: 'Vakantie', value: 'HOLIDAY'},
                            {label: 'Ziek', value: 'SICK'},
                            {label: 'Scholing', value: 'SCHOOLING'},
                            {label: 'Feestdag', value: 'BANK_HOLIDAY'},
                            {label: 'Overig', value: 'OTHER'}
                        ]}
                    />

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={() => editSuccessCallback(false)}>Annuleren</Button>
                <Button color={"error"} type={'submit'}>Opslaan</Button>
            </DialogActions>
        </form>

    </Dialog>
}