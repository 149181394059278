import {Button, Card, CardContent, Grid} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useCallback, useEffect, useState} from "react";
import AddImageModal from "./AddImageModal";
import axiosInstance from "@/features/auth/authService";
import {useDisclosure} from "@meierij-it/react-components-v2";
import EditImageModal from "@/features/workorders/images/EditImageModal";

interface Props {
    workOrderId: string | undefined;
    existingImages: Image[];
}

interface Image {
    id: number;
    image?: string;
    imageData?: string;
}

interface ImageSaveData {
    type: string;
    comment: string;
    imageData: string;
}

export default function ImageUploads({workOrderId, existingImages}: Props) {

    const [images, setImages] = useState<Image[]>([])
    const [addImageVisible, setAddImageVisible] = useState<boolean>(false)
    const [editImage, setEditImage] = useState<number | null>(null)

    const {isOpen, onOpen, onClose} = useDisclosure()

    useEffect(() => {
        setImages(existingImages)
        console.log(existingImages)
    }, [existingImages])

    const handleAddImage = () => {
        setAddImageVisible(true);
    }

    const handleImageDelete = (id: number) => {
        setEditImage(id)
        onOpen()
    }
    const imageList = images?.map((image: Image) => <img key={image.id} onClick={() => handleImageDelete(image.id)}
                                                         src={image.image ?? image.imageData} alt={'foto'}
                                                         style={{maxWidth: '50%'}}/>)

    const onAddImageClose = (image: ImageSaveData | null) => {
        if (!workOrderId) return;

        setAddImageVisible(false);

        axiosInstance.post(`/work-orders/add_image/`, {
            work_order_uuid: workOrderId,
            type: image?.type,
            comment: image?.comment,
            imageData: image?.imageData
        }).then((response) => {
            if (!response?.data)
                return;

            if (image) {
                setImages([...images, {id: response.data, imageData: image.imageData}])
            }

        }).catch((error) => {
            console.log(error)
        })
    }

    const handleEditClose = useCallback((deleted: boolean) => {
        if (deleted) {
            setImages(images.filter((image) => image.id !== editImage))
        }
        setEditImage(null)
        onClose()
    }, [onClose, setEditImage, editImage, images])

    return <Grid item xs={12} style={{maxWidth: '100vw'}}>
        <AddImageModal open={addImageVisible} onClose={onAddImageClose}/>
        <EditImageModal open={isOpen} onClose={handleEditClose} editImageId={editImage}/>
        <div>
            <Card style={{marginBottom: '8px'}}>
                <CardContent sx={{padding: '0 !important'}}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: '8px 16px'
                    }}>
                        <h3 style={{verticalAlign: 'middle !important'}}>Afbeeldingen (klik om te bewerken)</h3>
                        <Button sx={{
                            width: '50px',
                            color: '#000',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '16px'
                        }} onClick={handleAddImage}>
                            <AddIcon/>
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', overflowY: 'auto'}}>
            {imageList}
        </div>
    </Grid>
}