import {Grid} from "@mui/material";
import {TextareaField} from "@meierij-it/react-components-v2";

interface Props {
    errors: any;
    register: any;
    control: any;
}

export default function Comments({errors, register, control}: Props) {

    return (
        <>
            {/* Comment */}
            <Grid item xs={12} sx={{marginTop: '8px'}}>
                <h3 style={{marginTop: 0}}>Opmerking (komt op werkbon te staan)</h3>
                <TextareaField
                    control={control}
                    error={errors.general_remarks}
                    {...register("general_remarks")}
                />
            </Grid>

            {/* Comment next visit */}
            <Grid item xs={12} sx={{marginTop: '8px'}}>
                <h3 style={{marginTop: 0}}>Opmerking volgend bezoek</h3>
                <TextareaField
                    control={control}
                    error={errors.remarks_current_visit}
                    {...register("remarks_current_visit")}
                />
            </Grid>

            {/* Comment office */}
            <Grid item xs={12} sx={{marginTop: '8px'}}>
                <h3 style={{marginTop: 0}}>Opmerking voor kantoor</h3>
                <TextareaField
                    control={control}
                    error={errors.remarks_for_office}
                    {...register("remarks_for_office")}
                />
            </Grid>
        </>
    );
}