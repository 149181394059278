import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {StaticSelectField, TextareaField} from "@meierij-it/react-components-v2";
import axiosInstance from "@/features/auth/authService";

interface Props {
    open: boolean;
    editImageId: number | null;
    onClose: (deleted: boolean) => void;
}

const types = [
    {label: 'Algemeen', value: 'COMMON'},
    {label: 'Plantvervanging', value: 'REPLACEMENT'},
]

export default function EditImageModal({open, onClose, editImageId}: Props) {

    useEffect(() => {
        if (!editImageId) return;

        // fetch image data
        axiosInstance.get(`/work-order-images/${editImageId}/`).then((response) => {
            console.log(response.data)
            reset({
                type: types.find(type => type.value === response.data.type),
                comment: response.data.comment
            })
        })

    }, [editImageId])

    const {
        handleSubmit,
        register,
        control,
        watch,
        reset,
        formState: {errors, isDirty, isSubmitting},
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            type: {
                label: 'Algemeen',
                value: 'COMMON'
            },
            comment: ''
        }
    })

    const doSave = (data: any) => {
        // save new image data
        axiosInstance.patch(`/work-order-images/${editImageId}/`, {
            type: data.type.value,
            comment: data.comment
        }).then(() => {
            onClose(false)
        }).catch((error) => {
            console.log(error)
        });
    }

    const handleDelete = () => {
        if (!window.confirm('Weet je zeker dat je deze afbeelding wilt verwijderen?'))
            return;

        axiosInstance.post('/work-orders/delete_image/', {
            image_id: editImageId
        }).then(() => {
            onClose(true)
        }).catch((error) => {
            console.log(error)
        });

    }

    return <Dialog open={open} onClose={() => onClose(false)} fullWidth>
        <DialogTitle>Afbeelding bewerken</DialogTitle>
        <DialogContent dividers>
            <form onSubmit={handleSubmit(doSave)}>
                <StaticSelectField isRequired={true} name={'type'} control={control} options={types}/>
                <br/><br/>
                <TextareaField isRequired={true} name={'comment'} control={control} label={'Opmerking'}/>
            </form>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button variant={'outlined'} onClick={() => onClose(false)}>Annuleren</Button>
            <Button variant={'outlined'} color={'error'} onClick={handleDelete}>Verwijderen</Button>
            <Button variant={'contained'} onClick={handleSubmit(doSave)} disabled={!isDirty || isSubmitting}>Opslaan</Button>
        </DialogActions>
    </Dialog>
}