import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import {InputField} from "@meierij-it/react-components-v2";
import {useEffect, useState} from "react";
import axiosInstance from "@/features/auth/authService";
import moment from "moment";

interface Props {
    workOrderId: string | null;
    onClose: (refresh: boolean) => void
}

export default function EditTimeDialog({workOrderId, onClose}: Props) {

    const [startTime, setStartTime] = useState<string>('')
    const [endTime, setEndTime] = useState<string>('')

    const {
        register,
        control,
        reset,
        handleSubmit,
        formState: {isDirty},
    } = useForm({
        shouldUnregister: true,
    })

    useEffect(() => {
        if (workOrderId) {
            // Fetch data
            axiosInstance.get(`/work-orders/${workOrderId}`).then(response => {
                const {start_time, end_time} = response.data
                reset({
                    start_time: start_time ? moment(start_time, 'HH:mm:ss').format('HH:mm') : '',
                    end_time: end_time ? moment(end_time, 'HH:mm:ss').format('HH:mm') : ''
                })
            })
        }
    }, [workOrderId])

    const doSave = (data: any) => {
        axiosInstance.patch(`/work-orders/${workOrderId}/`, {
            start_time: data.start_time,
            end_time: data.end_time
        }).then(() => {
            onClose(true)
        })
    }

    return <Dialog open={!!workOrderId} onClose={() => onClose(false)} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(doSave)}>
            <DialogTitle>
                Tijden aanpassen
            </DialogTitle>
            <DialogContent style={{padding: '8px'}} dividers>
                <div style={{margin: '8px'}}>
                    <InputField label={'test'} name={'start_time'} control={control}/>
                </div>
                <div style={{margin: '8px'}}>
                    <InputField name={'end_time'} control={control}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Annuleren</Button>
                <Button type={'submit'} disabled={!isDirty} variant={'contained'} color={'success'}>Opslaan</Button>
            </DialogActions>
        </form>

    </Dialog>

}