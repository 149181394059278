import {Button, Card, CardActionArea, CardContent, Modal} from "@mui/material";
import Webcam from "react-webcam";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {useRef} from "react";
import {useForm} from "react-hook-form";
import {StaticSelectField, TextareaField} from "@meierij-it/react-components-v2";


interface ImageSaveData {
    type: string;
    comment: string;
    imageData: string;
}

interface Props {
    open: boolean;
    onClose: (image: ImageSaveData | null) => void;
}

const types = [
    {label: 'Algemeen', value: 'COMMON'},
    {label: 'Plantvervanging', value: 'REPLACEMENT'},
]

export default function AddImageModal({open, onClose}: Props) {

    const cameraRef = useRef<Webcam>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const {
        handleSubmit,
        register,
        control,
        watch,
        reset,
        formState: {errors, isDirty, isSubmitting},
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            type: {
                label: 'Algemeen',
                value: 'COMMON'
            }
        }
    })

    const handleCapture = () => {
        formRef.current?.requestSubmit();
    }

    const doSave = (data: any) => {
        const image = cameraRef.current?.getScreenshot();
        if(!image) return;
        onClose({
            type: data?.type.value,
            comment: data?.comment,
            imageData: image
        })
    }

    return <Modal open={open} onClose={() => onClose(null)}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Card sx={{width: '80%', height: '80%', position: 'relative'}}>
            <CardContent>
                <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'scroll'}}>
                    <Webcam ref={cameraRef} videoConstraints={{
                        facingMode: 'environment'
                    }} width={'100%'} style={{border: '1px solid black', maxHeight: '500px'}}/>
                    <form ref={formRef} style={{padding: '16px'}} onSubmit={handleSubmit(doSave)}>
                        <StaticSelectField isRequired={true} name={'type'} control={control} options={types}/>

                        <TextareaField isRequired={true} name={'comment'} control={control} label={'Opmerking'}/>
                    </form>
                </div>
            </CardContent>

            <CardActionArea onClick={handleCapture} style={{position: 'absolute', bottom: '0'}}>
                <Button sx={{
                    display: 'flex',
                    width: '100%',
                    height: '70px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#4AA560'
                }} variant={'contained'}><CameraAltIcon/></Button>
            </CardActionArea>
        </Card>
    </Modal>
}