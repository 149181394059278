import axiosInstance, {authenticate, useAuthStore} from "@/features/auth/authService";
import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {InputField, LoadingButton, PasswordField} from "@meierij-it/react-components-v2";
import {useNavigate} from "react-router-dom";
import logo from './logo.svg';
import useAppUserStore from "@/store/app-message.store";

interface LoginForm {
    email: string;
    password: string;
}

export default function Login() {

    const [loading, setLoading] = useState(false)
    const [buttonIsDisabled, setButtonIsDisabled] = useState(true)

    const {register, handleSubmit, control, watch} = useForm<LoginForm>();

    const navigate = useNavigate();

    const email = watch('email')
    const password = watch('password')

    const {setUser, setIsLoggedIn} = useAuthStore((state) => state)
    const {setMessage, setUUID} = useAppUserStore(state => state)

    useEffect(() => {
        setButtonIsDisabled(!email || !password)
    }, [email, password])

    const handleLogin = useCallback(async (data: LoginForm) => {
        setLoading(true)

        try {
            const [token, refreshToken] = await authenticate(data.email, data.password);

            localStorage.setItem("van_der_vleuten_pwa_token", token);
            localStorage.setItem("refreshToken", refreshToken);

            axiosInstance.post('day-entries/start_work/').catch((error) => {
                console.log(error)
            })

            axiosInstance.get('/users/get_current_user/').then(({ data }) => {
              setIsLoggedIn(true)
              setUser(data)

              if(data.employee) {
                setUUID(data.uuid)
              }
          })

            navigate('/')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [setLoading])

    return <div style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }}>
        <img src={logo} style={{width: '80%'}}/>
        <form onSubmit={handleSubmit(handleLogin)} style={{padding: '16px', width: '80vw'}}>
            <h2>Login</h2>
            <div>
                <InputField
                    isRequired={true}
                    type={'email'}
                    control={control}
                    label={'E-mailadres'}
                    {...register("email")}/>
            </div>
            <div style={{marginTop: '16px'}}>
                <PasswordField
                    isRequired={true}
                    type={'password'}
                    control={control}
                    label={'Password'}
                    {...register("password")} />
            </div>
            <LoadingButton disabled={buttonIsDisabled} loading={loading} sx={{marginTop: '16px', width: '100%', color: '#fff', background: '#02a160'}} type="submit"
                    variant={'contained'}>Login</LoadingButton>
        </form>
    </div>;
}