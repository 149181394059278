import React from 'react';
import './App.css';
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import WorkOrders from "./pages/WorkOrders";
import Hours from "./pages/Hours";
import WorkOrderDetails from './pages/WorkOrderDetails'

import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {PrivateRoute} from './components/PrivateRoute';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from 'moment';
import 'moment/locale/nl';  // Import the Dutch locale for moment.js
moment.locale('nl');  // Set the locale to Dutch


const router = createBrowserRouter([
    {
        path: "/",
        element: <Landing/>,
    },
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/workorders',
        element: <PrivateRoute><WorkOrders/></PrivateRoute>
    },
    {
        path: '/workorders/:workOrderId',
        element: <PrivateRoute><WorkOrderDetails/></PrivateRoute>
    },
    {
        path: '/hours',
        element: <PrivateRoute><Hours/></PrivateRoute>
    },
])

function App() {

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterProvider router={router}/>
            </LocalizationProvider>

        </>
    );
}

export default App;
