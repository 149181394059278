import {create} from 'zustand'

interface appUserStore {
    message: string | null
    UUID: string | null
    setUUID: (UUID: string | null) => void
    setMessage: (message: string | null) => void
}

const useAppUserStore = create<appUserStore>((set) => ({
    message: null,
    UUID: null,
    setMessage: (message: string | null) => set({message}),
    setUUID: (UUID: string | null) => set({UUID})
}))

export default useAppUserStore;