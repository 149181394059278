import {Alert, AlertTitle, Button, Grid, TextField} from "@mui/material";
import moment from "moment";
import {useCallback, useEffect, useRef, useState} from "react";
import _ from "lodash";
import {useForm} from "react-hook-form";
import {TextareaField} from "@meierij-it/react-components-v2";
import axiosInstance from "@/features/auth/authService";
import EditTimeDialog from "@/features/workorders/common-details/EditTimeDialog";

interface WorkOrder {
    id: number;
    title: string;
    description: string;
    client_id: number;
    contact: string;
    phone: string;
    street: string;
    postal_code: string;
    city: string;
    houseNumber: string;
    contract: string;
    date_visit: string;
    date_last_visit: string;
    period: number;
    week: number;
    route_number: number;
    start_time: string;
    end_time: string;
    remarks_last_visit: string;
    planters_big: number;
    planters_small: number;
    number_of_planters: number;
    contract_type: string;
    gve_count: number;
    customer_comment: string;
    reference: string;
    entity_uuid: string;
    mail_sent: boolean;
    uuid: string;
    confirmation_mail_address: string;
}

interface Props {
    workOrder: WorkOrder | undefined;
    refreshCallback: () => void;
}

export default function CommonDetails({workOrder, refreshCallback}: Props) {

    const [editTimeId, setEditTimeId] = useState<string | null>(null);

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        formState: {isDirty},
    } = useForm({
        shouldUnregister: true,
        defaultValues: {
            customer_comment: workOrder?.customer_comment || 'bla',
        },
    })

    const commentWatch = watch('customer_comment')

    useEffect(() => {
        reset({
            customer_comment: workOrder?.customer_comment
        })
    }, [workOrder?.customer_comment]);

    const saveComment = (comment: string) => {
        axiosInstance.patch(`/entities/${workOrder?.entity_uuid}/`, {
            comment: comment
        }).then((response) => {
            console.log(response)
        }).catch((error) => {
            console.log(error)
        })
    }

    const saveCommentDebounce = useCallback(_.debounce(saveComment, 1000), [workOrder]);

    useEffect(() => {
        if (isDirty) {
            saveCommentDebounce(commentWatch)
        }
    }, [commentWatch])


    const onEditTimeClick = () => {
        setEditTimeId(workOrder?.uuid ?? null)
    }

    const editTimeClose = (refresh: boolean) => {
        setEditTimeId(null)
        if (refresh) {
            refreshCallback()
        }
    }

    return <Grid container spacing={2}>
        <EditTimeDialog workOrderId={editTimeId} onClose={editTimeClose}/>
        {/* Description and client_id */}
        <Grid item xs={12} sm={8}>
            <h2>{workOrder?.description}</h2>
        </Grid>
        <Grid item xs={12} sm={4} className={'detail'}>
            <b>Debiteurnummer&nbsp;</b><span className={'value'}>{workOrder?.reference}</span>
        </Grid>

        {/* Contact */}
        <Grid item xs={12}>
            <b>Contactpersoon&nbsp;</b><span className={'value'}>{workOrder?.contact}</span>
        </Grid>

        {/* Address */}
        <Grid item xs={12}>
            <b>Adres&nbsp;</b><span className={'value'}>{workOrder?.street} {workOrder?.houseNumber}</span>
        </Grid>

        {/* PostalCode   City */}
        <Grid item xs={12} sm={6}>
            <b>Postcode&nbsp;</b><span className={'value'}>{workOrder?.postal_code}</span>
        </Grid>
        <Grid item xs={12} sm={6}>
            <b>Stad&nbsp;</b><span className={'value'}>{workOrder?.city}</span>
        </Grid>

        {/* Phone */}
        <Grid item xs={12}>
            <b>Telefoonnummer&nbsp;</b><span className={'value'}>{workOrder?.phone}</span>
        </Grid>

        {/* Contract Type */}
        <Grid item xs={12}>
            <b>Contract type&nbsp;</b><span className={'value'}>{workOrder?.contract_type}</span>
        </Grid>

        {/* Bins */}
        <Grid item xs={12} sm={3}>
            <b>Bakken&nbsp;</b>
        </Grid>
        <Grid item xs={4} sm={3}>
            <b>Groot&nbsp;</b><span className={'value'}>{workOrder?.planters_big}</span>
        </Grid>
        <Grid item xs={4} sm={3}>
            <b>Klein&nbsp;</b><span className={'value'}>{workOrder?.planters_small}</span>
        </Grid>
        <Grid item xs={4} sm={3}>
            <b>Totaal&nbsp;</b><span
            className={'value'}>{workOrder?.number_of_planters}</span>
        </Grid>

        {/* GVE count */}
        <Grid item xs={12}>
            <b>Aantal GVE's&nbsp;</b><span className={'value'}>{workOrder?.gve_count}</span>
        </Grid>

        {/* Dates visit */}
        <Grid item xs={12} sm={6}>
            <b>Datum bezoek&nbsp;</b><span className={'value'}>{workOrder?.date_visit}</span>
        </Grid>
        <Grid item xs={12} sm={3}>
            <b>Periode&nbsp;</b><span className={'value'}>{workOrder?.period}</span>
        </Grid>
        <Grid item xs={12} sm={3}>
            <b>Week&nbsp;</b><span className={'value'}>{workOrder?.week}</span>
        </Grid>

        {/* Dates last visit + route*/}
        <Grid item xs={12} sm={6}>
            <b>Vorige bezoek&nbsp;</b><span className={'value'}>{workOrder?.date_last_visit}</span>
        </Grid>
        <Grid item xs={12} sm={6}>
            <b>Route&nbsp;</b><span className={'value'}>{workOrder?.route_number}</span>
        </Grid>

        <Grid item xs={6}>
            <b>E-mail verstuurd&nbsp;</b><span
            className={'value'}>{workOrder?.mail_sent ? 'Ja' : 'Nee'}</span>
        </Grid>

        <Grid item xs={6}>
            <b>E-mail werkbon&nbsp;</b>
            <span className={'value'}
                  style={{wordBreak: 'break-all'}}>{workOrder?.confirmation_mail_address ?? '-'}</span>
        </Grid>

        {/* Arrival + departure time */}
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <Grid item xs={4}>
                <b>Aankomsttijd&nbsp;</b><br/><span
                className={'value'}>{workOrder?.start_time ? moment(workOrder?.start_time, 'HH:mm:ss.SSSSSS').format('HH:mm') : null}</span>
            </Grid>
            <Grid item xs={4}>
                <b>Vertrektijd&nbsp;</b><br/><span
                className={'value'}>{workOrder?.end_time ? moment(workOrder?.end_time, 'HH:mm:ss.SSSSSS').format('HH:mm') : null}</span>
            </Grid>
            <Grid item xs={4}>
                <Button style={{left: '8px', fontSize: '0.8rem'}} variant={'outlined'} onClick={onEditTimeClick}>Tijden
                    bewerken</Button>
            </Grid>
        </Grid>


        <Grid item xs={12} style={{display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center'}}>
            {/* Comment previous visit */}
            {workOrder?.remarks_last_visit ? (
                <Alert severity="info" sx={{width: '95%'}}>
                    <AlertTitle>Opmerking vorig bezoek</AlertTitle>
                    {workOrder?.remarks_last_visit}
                </Alert>
            ) : null}
        </Grid>

        {/* Comments / requirements */}
        <Grid item xs={12} sx={{marginTop: '8px'}}>
            <h3 style={{marginTop: 0}}>Opmerkingen / Benodigdheden</h3>
            <TextareaField
                control={control}
                name={'customer_comment'}
            />
        </Grid>

    </Grid>
}