import {AppBar, Button, IconButton, Toolbar, Typography} from "@mui/material";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useAppUserStore from "../../store/app-message.store";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Ticker from "react-ticker";
import axiosInstance from "@/features/auth/authService";

interface PageLayoutProps {
    backButton?: boolean;
    title: string;
    children: React.ReactNode;
    backPressUrl?: string;
    hasBottomBar?: boolean;
    onBackPress?: () => void;
}

export default function PageLayout({title, backButton, children, backPressUrl, hasBottomBar, onBackPress}: PageLayoutProps) {

    const navigate = useNavigate();

    const {message, setMessage} = useAppUserStore(state => state);

    const handleBackPressed = useCallback(() => {
        if (onBackPress) {
            onBackPress()
        }
        navigate(backPressUrl ?? '..')
    }, [])

    useEffect(() => {
        axiosInstance.get('daily-comment/get').then(response => {
            setMessage(response.data)
        }).catch(error => console.error(error))
    }, [])

    const handleSignOut = useCallback(() => {
        if (!window.confirm('Weet je zeker dat je wilt uitloggen? Je eindtijd wordt hierdoor geklokt.')) return
        axiosInstance.post('day-entries/stop_work/').catch(error => console.log(error)).finally(() => {
            axiosInstance.post('api/auth/jwt/token/logout/')
                .then((response: any) => {
                })
                .catch(error => console.error(error))
                .finally(() => {
                    setMessage(null)
                    localStorage.removeItem('token_pwa')
                    navigate('/login')
                })
        })

    }, [setMessage])

    return <div style={{height: '100vh', maxHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <AppBar position={'relative'}>
            <Toolbar>
                {backButton ?
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                        onClick={handleBackPressed}>
                        <ArrowBackIosIcon/></IconButton> :
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}>

                    </IconButton>}
                <Typography variant="body1" component="div" sx={{flexGrow: 1}}>
                    {title}
                </Typography>
                <Button color="inherit" onClick={handleSignOut}><ExitToAppIcon/></Button>
            </Toolbar>
        </AppBar>
        {message ?
            <AppBar position={'relative'} sx={{
                background: 'orange !important',
                color: 'black !important',
                width: '100vw',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 8px',
                fontSize: '1.2rem'
            }}>
                <Ticker>
                    {({index}) => (
                        <div style={{padding: '0 32px'}}>{message}</div>
                    )}
                </Ticker>

            </AppBar>
            : null}

        <div style={{
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
            flexGrow: 1,
            marginBottom: hasBottomBar ? '64px' : 0
        }}>
            {children}
        </div>
    </div>
}