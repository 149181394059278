import {Navigate, useLocation} from 'react-router-dom';
import { useAuthStore } from '@/features/auth/authService';


export function PrivateRoute({ children } : { children: any }) {
    const location = useLocation();
    const { isLoggedIn, isAuthLoading } = useAuthStore((state) => state)

    if (!isAuthLoading && !isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: location.pathname }} />
    }

    // authorized so return child components
    return children;
}